import './lazyHero.css'

const LazyHero = () => {
  return (
    <div className='moving-bg'>
      
    </div>
  );
}

export default LazyHero;
