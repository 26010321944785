import React from 'react';
import errorImg from '../../components/asset/errorImg.jpg'

const NotFound = () => {
  return (
    <div>
      <img src={errorImg} alt="errorImage" />
    </div>
  );
}

export default NotFound;
